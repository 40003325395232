import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from '../redux/store';
import NoPermissionPage from '../pages/NoPermissionPage';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
    children: PropTypes.node,
};

export default function RoleBasedGuard({ children }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { rolemodules } = useSelector((state) => state.roleaccess);
    const modules = rolemodules
        ?.flatMap((item) => item?.Modules?.flatMap((item) => item?.childrens?.map((item) => item?.path)))
        .filter((item) => item !== undefined);

    modules?.push(
        '/dashboard',
        '/page-content',
        '/profile',
        '/global-settings',
    ); // temporary for page content and global setting

    const isRouteAllowed = (route) => {
        
        if (pathname === '/') {
            navigate('/dashboard', { replace: true });
            return true;
        }

        const convertedRoute = route?.replace(/s$/, '');
        return pathname.startsWith(route) || pathname.startsWith(`${convertedRoute}/`);
    };

   
    if (!modules?.some((item) => isRouteAllowed(item))) {
        return <NoPermissionPage />;
    }

    return <>{children}</>;
}
